<template>
    <div class="tiaokuan">
        <div class="contractTerms">
            <div class="contractTermsBox">
                <div class="changeClose"><span @click="handleCloses">X</span> </div>
                <div class="contentS">
                    <div class="contracTitle">货运合约条款
                    </div>
                    <div class="contracContent" v-html="basicagent">
                        {{ basicagent }}
                    </div>
                    <div class="contracBot">
                        <!-- <div class="conLeft">
                        <div class="img" @click="agree">
                            <img :src="contract ? check : unchecked" alt="">
                        </div>
                        <p>
                            <span>我已阅读 </span>
                            <span>《国际货物进（出）口运输代理条款》</span>
                        </p>
                    </div> -->
                        <div class="conRight">
                            <!-- <div class="conRight1" @click="flagHeYue = !flagHeYue">上一步</div> -->
                            <div class="conRight2" @click="handleMes">我已阅读并同意本条款</div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { baseUrl } from '../../tools/baseUrl';
export default {
    name: "tiaoguan",
    props: ['handleMe', 'handleCloseMe', 'shipperName'],
    data() {
        return {
            basicagent: '',//合约信息
        }
    },
    created() {
        console.log(this.shipperName);
        this.flagHeYue = true;
        let reg = new RegExp('<[^<>]+>', 'g');
        axios.get(`${baseUrl}/basic/agent`, {
            headers: { 'Content-Type': 'h5' }
        }).then(res => {
            let names = this.shipperName
            let cont = res.data.result.content.replace('{{enterpriseName}}', names);
            this.basicagent = cont;

            //this.basicagent = res.data.result.content;
            // this.basicagent = this.basicagent.replace(reg, "");
            // console.log(this.basicagent)
        })
            .catch(err => {
                console.error(err);
            })
    },
    methods: {
        handleMes() {
            this.handleMe()
        },

        handleCloses() {
            this.handleCloseMe()
        },
    },
}
</script>

<style lang="scss" scoped>
.tiaokuan {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .contractTerms {
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(51, 51, 51, 0.1);
        width: 100%;
        // height: 900px;
        height: 100%;

        .contractTermsBox {
            // width: 1200px;
            // margin: 280px auto;
            width: 100%;
            background: #FFFFFF;
            // height: 780px;
            height: 100%;
            // overflow: hidden;    

            .contentS {
                width: 1200px;
                margin: 0 auto;
                overflow: hidden;
                padding-top: 20px;
            }

            .changeClose {
                position: absolute;
                right: 10px;
                top: 0px;
                width: 100%;
                font-size: 24px;
                cursor: pointer;
                text-align: right;
                background: rgb(#000000, .6);
            }

            .contracTitle {
                position: relative;
                font-size: 30px;
                font-weight: bold;
                color: #333333;
                text-align: center;
                margin-top: 20px;


            }

            .contracContent {
                padding: 10px 30px;

                p {
                    padding: 10px 30px;
                }

            }



            .contracBot {
                display: flex;
                justify-content: right;
                width: 100%;
                height: 64px;
                margin-top: 33px;
                background: #FFFFFF;
                padding: 0 20px;

                .conLeft {
                    display: flex;
                    align-items: center;

                    p {
                        margin-left: 5px;
                        line-height: 64px;
                    }
                }

                .conRight {
                    display: flex;

                    .conRight1 {
                        width: 150px;
                        height: 40px;
                        border-radius: 30px 30px 30px 30px;
                        border: 1px solid #1890FF;
                        text-align: center;
                        line-height: 40px;
                        font-size: 18px;
                        font-weight: 400;
                        margin-right: 59px;
                        color: #1890FF;
                        cursor: pointer;
                    }

                    .conRight2 {
                        width: 228px;
                        height: 40px;
                        background: #1890FF;
                        text-align: center;
                        line-height: 40px;
                        font-size: 18px;
                        border-radius: 30px 30px 30px 30px;
                        font-weight: 400;
                        color: #FFFFFF;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}
</style>